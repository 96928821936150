// returns date that is in past by comparing two dates
export const pastDate = (date_1: string, date_2: string): Date | null => {
	if (!date_1 && !date_2) {
		// throw new Error('Both dates are missing');
		return null;
	}
	if (!date_1) {
		return new Date(date_2);
	}
	if (!date_2) {
		return new Date(date_1);
	}

	const date1 = new Date(date_1);
	const date2 = new Date(date_2);

	if (date1 > date2) {
		return date2;
	}
	return date1;
};
