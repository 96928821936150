<script lang="ts">
	import type { Article } from '$types/articles.types';
	import { goto } from '$app/navigation';
	import { LOCALIZELINK } from '$utils/link';
	import { getContext } from 'svelte';
	import { pastDate } from '$utils/pastDate';
	import type { LANGUAGE } from '$lib/constants/locale';
	export let article: Article;

	let lang = getContext('lang') as LANGUAGE;

	const formatDate = (date: Date | null) => {
		if (!date) {
			return '';
		}
		return date.toLocaleString('en-US', {
			month: 'long',
			day: 'numeric',
			year: 'numeric'
		});
	};

	const handleArticleClick = () => {
		goto(LOCALIZELINK(lang, article.category.value, article.pageID));
	};
</script>

<a
	class="w-360px card cursor-pointer"
	href={LOCALIZELINK(lang, article.category.value, article.pageID)}
>
	<div class="img-container w-360px h-224px overflow-clip transition-all duration-500">
		<img
			src={article?.featuredImage?.url}
			class="h-full w-full scale-110 object-cover transition-all duration-500"
			alt={article?.featuredImage?.alt || ''}
			loading="lazy"
		/>
	</div>
	<div class="mt-5">
		<div class="text-grey-500">
			Traveling | {article.readTime ?? 0} min | {formatDate(
				pastDate(article.date, article.createdAt)
			)}
		</div>

		<div class="karla-medium !font-600 text-18px text-grey-800 title my-4">
			{article.title}
		</div>
	</div>
</a>

<style type="postcss">
	.img-container:hover img {
		transform: scale(1);
		transition: all 0.5s ease-in-out;
	}
	.card:hover .title {
		text-decoration: underline;
		transition: all 0.5s ease-in-out;
	}
</style>
